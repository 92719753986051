<template>
    <div>
        <div class="layout">
            <!--
                Skiplinks link to landmarks in the website.
                Don't forget to add the IDs below to your custom elements.
                Keep in mind there can only be 1 <main> and 1 <footer> component in the context of the body
            -->
            <SkipLinks />

            <TheNavBar />
            <TheMenuOverlay />

            <slot />

            <TheFooter />
        </div>

        <!-- Only add the consent module when you've enabled the cookie-consent layer -->
        <!-- NOTE: At the moment there's issues with SSR, that's why a ClientOnly wrapper was added. Feel free to investigate. -->
        <TheConsentModule />
    </div>
</template>

<script setup>
import NavigationQuery from '~/graphql/queries/Navigation.graphql';
import { useGeneralStore } from '~/store/general';

const i18nHead = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true
});

const generalStore = useGeneralStore();

const { data: navigation } = await useAsyncQuery({
    query: NavigationQuery
});

generalStore.setNavigation(toValue(navigation));

useHead({
    htmlAttrs: {
        ...i18nHead.value.htmlAttrs
    },
    bodyAttrs: {
        'menu-open': generalStore.isMainMenuOpen
    }
});
</script>

<style lang="less">
.layout {
    padding-top: var(--navbar-height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}
</style>
