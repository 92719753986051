<template>
    <DelayHydration>
        <footer
            id="footer-landmark"
            class="footer layout__footer"
        >
            <div class="footer__container">
                <h3
                    v-if="footerData.title"
                    class="footer__title"
                >
                    {{ footerData.title }}
                </h3>

                <nav
                    v-if="primaryMenuLinks && primaryMenuLinks.length"
                    class="footer__primary-navigation"
                >
                    <ul>
                        <li
                            v-for="(link, index) in primaryMenuLinks"
                            :key="`footer-primary-${index}`"
                        >
                            <CraftLink :link="link">
                                {{ link.text }}
                            </CraftLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </footer>
    </DelayHydration>
</template>

<script setup>
import { useGeneralStore } from '~/store/general';
const generalStore = useGeneralStore();
const { footerData } = storeToRefs(generalStore);

const primaryMenuLinks = computed(() => {
    return footerData.value?.primaryNavigation ?? [];
});
</script>

<style lang="less" src="./TheFooter.less" />
