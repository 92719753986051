<template>
    <div class="hamburger-menu">
        <button
            :class="{ 'open': mainMenuOpen }"
            type="button"
            class="hamburger-menu__button button--transparent button--icon-only"
            aria-label="Open menu"
            @click="generalStore.toggleMainMenu()"
        >
            <i class="dn-icon hamburger-menu__icon">
                <span />
                <span />
                <span />
                <span />
            </i>
        </button>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useGeneralStore } from '~/store/general';

const generalStore = useGeneralStore();
const { mainMenuOpen } = storeToRefs(generalStore);
</script>

<style lang="less" src="./TheHamburgerMenu.less"></style>
