import { useCookies } from '@vueuse/integrations/useCookies';

const COOKIE_TRUE = 1;
const COOKIE_FALSE = 0;

const isNuxt = () => !!useNuxtApp;

/**
 * Wrapper to choose nuxt `useCookie` or `@vueuse/useCookies` depending on if we are using nuxt or just vue.
 *
 * @param {string} name
 * @param {object} opts
 * @returns {*|Awaitable<CookieRef<string>|WritableComputedRef<*>>}
 */
const useUniversalCookie = (name, opts) => {
    if (isNuxt()) {
        return useCookie(name, opts);
    }

    // Add 'universal-cookie' & 'vueuse/integrations' to dependencies in package.json
    const { get, set } = useCookies([], { autoUpdateDependencies: true });

    return computed({
        // getter
        get() {
            return get(name) ?? opts?.default();
        },
        // setter
        set(newValue) {
            set(name, newValue, opts);
        }
    });
};

function useContentCookie(name, opts) {
    unref(opts).maxAge = 365 * 24 * 60 * 60;
    return useUniversalCookie(name, opts);
}

const cookieDismissed = useContentCookie('cookiebar_dismissed', { default: () => COOKIE_FALSE });
const checkedCategories = useContentCookie('cookiebar_categories', { default: () => [] });

/**
 * Wrapper to choose `useState` or `ref` depending on if we are using nuxt or just vue.
 *
 * @template T
 * @param {string} key
 * @param {() => T} init
 * @returns {T}
 */
const useUniversalState = (key, init) => {
    if (isNuxt()) {
        return useState(key, init);
    }

    return ref(init());
};

// /**
//  * Check if consent was given for a specific category
//  *
//  * @param   {String}  categoryId  Category ID
//  * @return  {Boolean}
//  */
// const hasConsent = categoryId => isChecked(categoryId);

/*
 * composables
 */

export const useCookieConsent = () => {
    // fixme: implement isSaving logic
    const isSaving = useUniversalState('@dn/cookiebar/isSaving', () => false);
    const config = useUniversalState('@dn/cookiebar/config', () => {
        return {
            expires: 365,
            categories: [
                { id: 'functional', required: true },
                { id: 'analytical' },
                { id: 'marketing' }
            ],
        };
    });

    const { push } = useTagmanager();

    const categories = computed(() => {
        return config.value.categories;
    });

    /**
     * Get array of categories, as configured in the plugin.
     *
     * @return  {Array}  Array containing category objects
     */
    const getCategories = () => {
        return config.value.categories;
    };

    const isCookiebarDismissed = computed(() => {
        return cookieDismissed.value === COOKIE_TRUE;
    });

    const dismissCookiebar = () => {
        cookieDismissed.value = COOKIE_TRUE;
    };

    const undismissCookiebar = () => {
        cookieDismissed.value = COOKIE_FALSE;
    };

    const resetConsent = () => {
        uncheckAllCategories();
        undismissCookiebar();

        push({
            event: 'consent_changed',
            consent_categories: checkedCategories.value // eslint-disable-line camelcase
        });
    };

    const acceptAllCookies = () => {
        checkAllCategories();
        dismissCookiebar();

        push({
            event: 'consent_changed',
            consent_categories: checkedCategories.value // eslint-disable-line camelcase
        });
    };

    /**
     * Check ALL available categories
     * @return  {void}
     */
    const checkAllCategories = () => {
        for (const category of categories.value) {
            checkCategory(category.id);
        }
    };

    /**
     * Uncheck ALL available categories
     * @return  {void}
     */
    const uncheckAllCategories = () => {
        for (const category of categories.value) {
            uncheckCategory(category.id);
        }
    };

    /**
     * Get a single category object, by providing its id.
     *
     * @param   {String}  categoryId  Category's unique ID
     * @return  {Object}  Category object
     */
    const getCategoryById = (categoryId) => {
        return getCategories()
            .find(({ id }) => id === categoryId);
    };

    /**
     * Check if the requested category is actually available.
     *
     * @param   {String}  categoryId  Category's unique ID
     * @return  {Boolean}
     */
    const isValidCategory = (categoryId) => {
        return !!getCategoryById(categoryId);
    };

    /**
     * Check a specific category
     *
     * @param   {String}  categoryId  ID of the category
     * @return  {void}
     */
    const checkCategory = (categoryId) => {
        if (isChecked(categoryId) || !isValidCategory(categoryId)) {
            return;
        }

        // fixme: nuxt's useCookie() does not watch deep, so doesn't see .push() calls.
        // checkedCategories.value.push(categoryId);

        checkedCategories.value = [
            ...checkedCategories.value,
            categoryId
        ];
    };

    /**
     * Check if a specific category is always required.
     *
     * This means it cannot be unchecked.
     *
     * @param   {String}  categoryId  Category's unique ID
     * @return  {Boolean}
     */
    const isRequiredCategory = (categoryId) => {
        const category = getCategoryById(categoryId);

        if (!category) {
            return false;
        }

        return !!category.required;
    };

    /**
     * Uncheck a specific category
     *
     * Note: Categories marked as required cannot be unchecked.
     *
     * @param   {String}  categoryId  ID of the category
     * @return  {void}
     */
    const uncheckCategory = (categoryId) => {
        const categoryIndex = checkedCategories.value.indexOf(categoryId);

        if (categoryIndex === -1 || isRequiredCategory(categoryId)) {
            return;
        }

        // fixme, nuxt's useCookie does not use deep, so replace the entire array!
        const copy = [...checkedCategories.value];
        copy.splice(categoryIndex, 1);

        checkedCategories.value = copy;
    };

    /**
     * Check if a category is currently checked
     *
     * @param   {String}  categoryId  ID of the category
     * @return  {Boolean}
     */
    const isChecked = (categoryId) => {
        return checkedCategories.value?.includes(categoryId);
    };

    return {
        resetConsent,
        acceptAllCookies,
        config,
        dismissCookiebar,
        isCookiebarDismissed,
        isSaving,
        categories,
        checkedCategories,
        checkCategory,
        checkAllCategories,
        isRequiredCategory,
        isChecked,
        uncheckCategory
    };
};
