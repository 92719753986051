<template>
    <DelayHydration>
        <transition name="menu-overlay">
            <FocusLoop
                :is-visible="generalStore.isMainMenuOpen"
                class="menu-overlay"
                role="dialog"
                :auto-focus="false"
            >
                <nav class="menu-overlay__container">
                    <ul
                        v-if="primaryMenuLinks && primaryMenuLinks.length"
                        class="menu-overlay__list"
                    >
                        <li
                            v-for="(link, index) in primaryMenuLinks"
                            :key="`primary-menu-${index}`"
                        >
                            <CraftLink
                                :link="link"
                                @click="generalStore.closeMainMenu()"
                            />
                        </li>
                    </ul>
                </nav>
            </FocusLoop>
        </transition>
    </DelayHydration>
</template>

<script setup>
import { FocusLoop } from '@vue-a11y/focus-loop';
import { useGeneralStore } from '~/store/general';

// Close the menu when changing routes
const generalStore = useGeneralStore();
const route = useRoute();
const { mainNavigationData } = storeToRefs(generalStore);

const primaryMenuLinks = computed(() => {
    return mainNavigationData.value?.primaryNavigation ?? [];
});

watch(() => route.path, () => {
    generalStore.closeMainMenu();
});
</script>

<style lang="less" src="./TheMenuOverlay.less"></style>
