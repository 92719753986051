<template>
    <nav
        class="language-switcher"
        :aria-expanded="dropdownVisible"
        @keyup.escape="dropdownVisible = false"
    >
        <button
            type="button"
            class="language-switcher__button"
            @click="dropdownVisible = !dropdownVisible"
        >
            {{ currentLanguage.label }}

            <BaseIcon icon="language" />
        </button>

        <ul
            v-show="dropdownVisible"
            class="language-switcher__menu"
        >
            <li
                v-for="language in languageOptions"
                :key="language.code"
            >
                <a :href="language.url">{{ language.label }}</a>
            </li>
        </ul>
    </nav>
</template>

<script setup>
const { locale: currentLocale, locales } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const dropdownVisible = ref(false);

const labels = {
    nl: 'Nederlands',
    en: 'English'
};
/*
 * Fetch the localized uri's for the current page
 */
const gqlQuery = `query localizedUri($site: [String!]!, $uri: [String!]) {
    entry(site: $site, uri: $uri) {
        uri
        url
        language
        localized {
            uri
            url
            language
        }
    }
}`;

const { variables } = useCraftGraphql();

const { data } = await useAsyncQuery({
    query: gqlQuery,
    variables
});

/*
 *
 */
const languageOptions = computed(() => {
    const entry = toValue(data)?.entry;

    // If a Craft entry is present, use its localized options
    if (entry && entry.localized) {
        return entry.localized.map((locale) => {
            return {
                url: locale.url,
                label: labels[locale.language],
                code: locale.language
            };
        });
    }

    // If not we're dealing with a static Nuxt page.
    // Fall back on the i18n locales
    return toValue(locales)
        .filter(locale => locale.code !== toValue(currentLocale))
        .map(locale => {
            return {
                url: switchLocalePath(locale.code),
                label: labels[locale.code],
                code: locale.code
            };
        });
});

const currentLanguage = computed(() => {
    return {
        label: labels[toValue(currentLocale)]
    };
});
</script>

<style lang="less" src="./TheLanguageSwitcher.less" />
