<template>
    <ClientOnly>
        <TheCookieBar
            :consent-data="cookieConsentData"
            @open-preferences="isModalOpen = true"
        />
    </ClientOnly>

    <TheCookieModal
        :is-open="isModalOpen"
        :consent-data="cookieConsentData"
        @close="onClosePreferences"
        @save="onSavePreferences"
    />
</template>

<script setup>
import CookieConsentQuery from '~/graphql/CookieConsent.graphql';

const isModalOpen = ref(false);

const onClosePreferences = () => {
    isModalOpen.value = false;
};

const { dismissCookiebar } = useCookieConsent();
const onSavePreferences = () => {
    dismissCookiebar();
    isModalOpen.value = false;
};

const { data } = await useLazyAsyncQuery({
    query: CookieConsentQuery
});

const cookieConsentData = computed(() => data?.value?.entry);
</script>

<style src="./TheConsentModule.less" lang="less" />
