<template>
    <Transition
        name="cookiebar"
        appear
    >
        <div
            v-if="!isCookiebarDismissed"
            class="cookiebar"
            role="alert"
            data-nosnippet
        >
            <div class="cookiebar__body">
                <h2 class="cookiebar__title">{{ cookiebarText.title }}</h2>

                <div class="cookiebar__description" v-html="cookiebarText.description" /> <!-- eslint-disable-line -->

                <div class="cookiebar__actions">
                    <BaseButton @click="$emit('openPreferences')">
                        {{ cookiebarText.managePreferencesButtonLabel }}
                    </BaseButton>

                    <BaseButton
                        class="cookiebar__accept"
                        @click="acceptAllCookies"
                    >
                        {{ cookiebarText.acceptButtonLabel }}
                    </BaseButton>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const props = defineProps({
    consentData: {
        type: Object,
        default: null
    }
});

const { consentData } = toRefs(props);

const { t } = useI18n();
const { acceptAllCookies, isCookiebarDismissed } = useCookieConsent();

const cookiebarText = computed(() => {
    return {
        title: consentData.value?.title ?? t('cookieConsent.cookiebar.title'),
        description: consentData.value?.cookiebarText ?? t('cookieConsent.cookiebar.description'),
        managePreferencesButtonLabel: t('cookieConsent.cookiebar.managePreferencesButton'),
        acceptButtonLabel: t('cookieConsent.cookiebar.accept_button')
    };
});

defineEmits([
    'openPreferences'
]);
</script>

<style src="./TheCookieBar.less" lang="less" />
