<template>
    <form class="cookie-preferences">
        <ul class="cookie-preferences__categories">
            <li
                v-for="category in categories"
                :key="category.id"
                :data-required="isRequiredCategory(category.id)"
                :data-selected="isChecked(category.id)"
                class="cookie-preferences__category"
            >
                <!-- @TODO: replace this by the base checkbox -->
                <div class="cookie-preferences__category-toggle">
                    <input
                        v-if="category.id === 'functional'"
                        type="checkbox"
                        :checked="true"
                        disabled
                    >
                    <input
                        v-else
                        :id="category.id"
                        v-model="checkedCategories"
                        type="checkbox"
                        :value="category.id"
                        :checked="isRequiredCategory(category.id)"
                        :name="category.id"
                    >
                </div>

                <label
                    :for="category.id"
                    class="cookie-preferences__category-description"
                >
                    <strong>{{ preferenceText[category.id].name }}</strong>
                    <span v-html="preferenceText[category.id].description"></span> <!-- eslint-disable-line -->
                </label>
            </li>
        </ul>
    </form>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { categories, checkedCategories, isChecked, isRequiredCategory } = useCookieConsent();

const { t } = useI18n();

const props = defineProps({
    consentData: {
        type: Object,
        default: null
    }
});

const preferenceText = computed(() => {
    return {
        functional: {
            name: t('cookieConsent.preferences.functional.name'),
            description: props.consentData?.functionalCookieDescription || t('cookieConsent.preferences.functional.description')
        },
        analytical: {
            name: t('cookieConsent.preferences.analytical.name'),
            description: props.consentData?.analyticalCookieDescription || t('cookieConsent.preferences.analytical.description')
        },
        marketing: {
            name: t('cookieConsent.preferences.marketing.name'),
            description: props.consentData?.marketingCookieDescription || t('cookieConsent.preferences.marketing.description')
        }
    };
});
</script>

<style src="./TheCookiePreferences.less" lang="less" />
