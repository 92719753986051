<template>
    <DelayHydration>
        <nav class="navigation-bar">
            <div class="navigation-bar__container">
                <NuxtLinkLocale
                    class="navigation-bar__logo"
                    :to="localePath('/', activeLocale.code)"
                >
                    <img
                        src="~/assets/img/placeholder.svg"
                        alt="Logo"
                        loading="eager"
                        fetchpriority="high"
                    >
                </NuxtLinkLocale>

                <div class="navigation-bar__actions">
                    <TheLanguageSwitcher />

                    <TheHamburgerMenu />
                </div>
            </div>
        </nav>
    </DelayHydration>
</template>

<script setup>
const { locale: activeLocale } = useI18n();
</script>

<style lang="less" src="./TheNavBar.less"></style>
