<template>
    <BaseModal
        :is-visible="isOpen"
        class="cookie-modal"
    >
        <h1 class="cookie-modal__title">{{ modalText.title }}</h1>

        <div class="cookie-modal__description" v-html="modalText.description" /> <!-- eslint-disable-line -->

        <TheCookiePreferences
            class="cookie-modal__preferences"
            :consent-data="consentData"
        />

        <BaseButton
            class="cookie-modal__submit"
            @click="$emit('save')"
        >
            {{ modalText.cta }}
        </BaseButton>
    </BaseModal>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false
    },
    consentData: {
        type: Object,
        default: null
    }
});

defineEmits([
    'save'
]);

const modalText = computed(() => {
    return {
        title: props.consentData?.cookiePreferencesTitle || t('cookieConsent.modal.title'),
        description: props.consentData?.cookiePreferencesDescription || t('cookieConsent.modal.description'),
        cta: t('cookieConsent.modal.cta')
    };
});
</script>

<style src="./TheCookieModal.less" lang="less" />
